<template>
  <div class="list-wrap wrapper">
    <BaseLoad v-if="loading" />
    <div class="list">
      <h2>我的收藏</h2>

      <div class="my-favor">
        <div class="between">
          <div class="sub-nav">
            <li
              v-for="(item, index) in subTab"
              :key="item"
              :class="[index == 1 ? 'sub-act' : '']"
              @click="changeSubTab(index)"
            >
              {{ item }}
            </li>
          </div>
          <p class="sub-tip">
            <span>游戏</span>
            <span class="sub-tip-total"> {{ total }} </span>个
          </p>
        </div>
        <div class="line"></div>
        <ListLoad v-if="listLoad && !loading" />
        <div class="my-favor-con" v-if="!listLoad">
          <ul class="game-list" v-if="list.length > 0">
            <li class="game-item" v-for="item in list" :key="item.config_id">
              <p class="delete flex">
                <span @click.stop="handleFavorDel(item.col_id, 2)">
                  <i class="iconfont icon-shanchu"></i>
                  删除
                </span>
              </p>
              <img
                class="cover"
                :src="preSrc + item.cover"
                alt=""
                @click.prevent="toPages(item.game_id, '', item.title)"
              />
              <h2
                :title="item.name"
                class="ellipsis"
                @click.prevent="toPages(item.game_id, '', item.title)"
              >
                {{ item.name }}
              </h2>
            </li>
          </ul>
        </div>
      </div>

      <BaseEmpty v-if="!listLoad && !loading && list.length == 0" />
      <!-- 分页 -->
      <div class="pagination-con" v-if="list.length > 0 && total > 10">
        <el-pagination
          :page-size="pageSize"
          :pager-count="5"
          layout="prev, pager, next"
          :total="total"
          :current-page="page"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
      <!-- 分页end -->
    </div>
  </div>
</template>
<script>
import BaseEmpty from "@/components/empty/BaseEmpty.vue";
import BaseLoad from "@/components/loading/BaseLoading.vue";
import ListLoad from "@/components/loading/ListLoading.vue";
import { handlePreview } from "@/tools/GlobalFunction";
export default {
  components: {
    BaseEmpty,
    BaseLoad,
    ListLoad,
  },
  data() {
    return {
      preSrc: config.src,
      listLoad: false,
      loading: true,
      subTab: ["课件", "游戏 "],
      list: [],
      page: 1,
      total: 0,
      pageSize: 10,
    };
  },
  created() {
    let _self = this;
    _self.favourList(1);
  },
  methods: {
    //我的收藏
    favourList(page) {
      let _self = this;
      _self.axios
        .post(
          config.url.myFavour,
          _self.$qs.stringify({ page, size: _self.pageSize, type: 2 })
        )
        .then((res) => {
          if (res.code == 200) {
            let list = res.data.list;
            list.forEach((item) => (item.show = false));
            _self.list = list;
            _self.total = res.data.total;
            _self.loading = false;
            _self.listLoad = false;
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
            _self.loading = false;
            _self.listLoad = false;
          }
        });
    },
    // 切换子选项卡
    changeSubTab(index, tab) {
      let _self = this;
      if (index == 1) {
        return;
      }
      _self.$router.push({
        name: "my-favor",
      });
    },
    toPages(id, type, title) {
      let _self = this;
      if (type == 1) {
        _self.$router.push({
          name: "course-info",
          params: {
            courseId: id,
          },
        });
      }
      if (type == 2) {
        _self.$router.push({
          name: "level-info",
          params: {
            levelId: id,
          },
        });
      }
      if (type == 3) {
        // _self.$router.push({
        //   name: "lesson-info",
        //   params: {
        //     lessonId: id,
        //   },
        // });
        handlePreview(id);
      }
      if (!type) {
        _self.$router.push({
          name: "game-info",
          params: {
            id: id,
          },
        });
      }
    },
    // 删除
    handleFavorDel(id, type) {
      let _self = this;
      _self.axios
        .post(config.url.myFavourDel, _self.$qs.stringify({ col_id: id, type }))
        .then((res) => {
          if (res.code == 200) {
            res.tip == 1
              ? _self.showTip("success", res.msg)
              : _self.showTip("success", "删除成功");
            _self.page = 1;
            _self.favourList(_self.page);
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
          }
        });
    },
    // 改变分页
    handleCurrentChange(val) {
      let _self = this;
      _self.page = val;
      _self.favourList(val);
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  background: #f9f9f9;
  .content {
    background: #fafafa;
  }
}
.con {
  display: flex;
  margin-top: 60px;
  background: #f9f9f9;
  .tab {
    width: 190px;
    height: 440px;
    box-sizing: border-box;
    margin-right: 30px;
    border-radius: 10px;
    background: #fff;
    h3 {
      font-size: 20px;
      font-weight: bold;
      padding: 30px 15px;
      box-sizing: border-box;
    }
    h3::before {
      content: "";
      display: inline-block;
      background: url("../../assets/img/home/pre-icon.png") center center
        no-repeat;
      background-size: 100%;
      width: 10px;
      height: 10px;
      margin-right: 6px;
    }
    li {
      padding: 15px 35px;
      box-sizing: border-box;
      // width: 100%;
      border-right: 2px solid #fff;
      margin-bottom: 20px;
    }
    li:hover {
      cursor: pointer;
      color: $color-main;
      border-right: 2px solid $color-main;
      background: #fff7f1;
    }
    li.active {
      color: $color-main;
      border-right: 2px solid $color-main;
      background: #fff7f1;
    }
  }
  .list {
    width: 980px;
    padding: 30px;
    box-sizing: border-box;
    background: #fff;
    border-radius: $border-radius-main;
    h2 {
      margin-bottom: 40px;
    }
    .list-item {
      background: #f9f9f9;
      padding: 20px 30px;
      box-sizing: border-box;
      border-radius: $border-radius-main;
      margin-bottom: 30px;
      > p {
        margin-bottom: 20px;
        color: #9f9f9f;
        font-size: 16px;
      }
    }
  }

  .icon-shoucang,
  .icon-star-filled {
    color: #f9e457;
    margin-right: 7px;
  }
  .icon-shoucang,
  .icon-star-filled {
    color: #f9e457;
    margin-right: 7px;
  }
  .favor i,
  .work img,
  .btn:hover,
  .sub-nav:hover {
    cursor: pointer;
  }
  .favor-work {
    color: $font-color-9f;
    justify-content: flex-start;
    .icon-yulan {
      color: #34c3f9;
      margin: 3px 6px 0 0;
    }
    .slash {
      margin: 0 10px;
    }
    .favor {
      margin-top: -3px;
    }
    .favor i {
      margin-right: 6px;
    }
    .work {
      display: flex;
      align-items: center;
      img {
        display: inline-block;
        width: 18px;
        height: 18px;
        object-fit: contain;
        margin-right: 10px;
      }
    }

    .icon-bianji1 {
      color: #03cca6;
    }
  }
  .delete {
    margin-bottom: 4px;
    justify-content: flex-end;
    color: $font-color-9f;
    span {
      cursor: pointer;
    }
    .icon-shanchu {
      cursor: pointer;
      color: #ff4d4f;
      margin-right: 2px;
    }
  }
  // 已购课程
  .course-list {
    display: flex;
    align-items: flex-start;
    position: relative;
    > div,
    ul {
      width: 920px;
      flex-shrink: 0;
      flex-grow: 0;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .course-list-item {
      margin-right: 40px;
      .course-list-favor-work {
        width: 165px;
        margin-left: 100px;
        justify-content: flex-end;
        display: none;
      }
    }
    li {
      width: 280px;
      height: 187px;
      overflow: hidden;
      position: relative;
      margin: 0 20px 20px 0;
      border-radius: $border-radius-main;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
      transition: 0.4s ease-in-out;
      p {
        font-size: 16px;
        z-index: 3;
        color: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 13px 20px;
        width: 100%;
        text-align: center;
        background: rgba(0, 0, 0, 0.5);
        box-sizing: border-box;
        border-radius: 0 0 10px 10px;
      }
    }
    .course-list-item:nth-child(3n),
    li:nth-child(3n),
    li:last-of-type {
      margin-right: 0;
    }
    li:hover {
      background: rgba(0, 0, 0, 0.4);
      box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.05);
      transition: 0.4s;
      cursor: pointer;
    }
  }
  .my-game,
  .my-favor,
  .my-workplace {
    width: 920px;
    .sub-nav {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      li {
        font-size: 18px;
        color: $font-color-51;
        width: 120px;
        text-align: center;
        flex-shrink: 0;
        flex-grow: 0;
      }
      li::after {
        content: "";
        display: block;
        margin: 20px auto 0;
        width: 50px;
        height: 5px;
        background: #f9f9f9;
        border-radius: 5px;
      }
      li.sub-act::after {
        content: "";
        display: block;
        margin: 20px auto 0;
        width: 50px;
        height: 5px;
        background: $color-main;
        border-radius: 5px;
      }
    }
  }
  .line {
    width: 100%;
    height: 10px;
    background: #f9f9f9;
    margin-top: -8px;
    border-radius: 5px;
  }
  .game-list {
    display: flex;
    margin-top: 30px;
    .game-item {
      cursor: pointer;
      width: 280px;
      height: 256px;
      padding: 20px;
      box-sizing: border-box;
      border-radius: $border-radius-main;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
      margin: 0 40px 20px 0;
      .delete {
        cursor: default;
        margin-bottom: -4px;
        span,
        i {
          cursor: pointer;
        }
      }
      .cover {
        width: 160px;
        height: 160px;
        object-fit: contain;
        margin: 0 auto 16px;
        border-radius: 50%;
      }
      h2 {
        font-size: 24px;
        color: $font-color-main;
        margin: 0 auto 24px;
        text-align: center;
      }
    }
    .game-item:nth-child(3n) {
      margin: 0 0 20px 0;
    }
  }
  .my-favor-con {
    margin-top: 30px;
  }

  .sub-tip {
    font-size: 18px;
    color: $font-color-51;
    .sub-tip-total {
      color: $color-main;
    }
  }
  .list-item.workpalce-course-list {
    padding: 0;
    background: #fff;
    margin-top: 30px;
    .item-r {
      h3 {
        margin-bottom: 16px;
      }
    }
  }
}
</style>
